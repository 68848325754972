<template>
  <div class="main-open">
    <div class="main-open-bg2"></div>
    <router-view/>
  </div>

  <ifBrowsers></ifBrowsers>
</template>

<script>
import ifBrowsers from '../components/ifBrowsers/index.vue'
export default {
  components: {
    ifBrowsers
  },
  data() {
    return {
    }
  },
  mounted() {
    document.getElementById("body").className = "body-height-100"
  },
}
</script>

<style lang="scss" scoped>
@import '../css/_var.scss';
/*开放页面 登录 注册*/
.main-open {
  width: 100vw;
  height: 100vh;
  min-width: $minWidth;
  min-height: $minHeight;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
}

.main-open-inner {
  width: 1920px;
  height: 1080px;
  min-width: $minWidth;
  min-height: $minHeight;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  z-index: 5;

  .content {
    width: 950px;
    height: 740px;
    margin-top: 550px;

    background-size: 100% 100%;

    display: flex;
    justify-content: center;

    box-sizing: border-box;
  }
}

.main-open-bg2 {
  display: none;
}

@media screen and (max-width: 1920px) {

}
</style>
