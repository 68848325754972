import {createRouter, createWebHashHistory} from 'vue-router'
//import Layout from '@/layout'
import empty from '@/layout/empty'
import open from '@/layout/open'
import platform from '@/layout/platform'

const openRoutes = [
    {
        path: '/login',
        component: open,
        redirect: '/login',
        name: "login",
        meta: {title: '登录', classify: 'login', icon: 'login', affix: true, isShow: 0},
        children: [
            {
                path: '/login',
                component: () => import('@/views/login/index'),
                name: 'login',
                meta: {title: '登录', classify: 'login', icon: 'login', affix: true, isShow: 0}
            }
        ]
    },
    /*{
        path: '/',
        component:open,
        redirect: '/index',
        name: "index",
        meta: { title: '首页', classify: 'index', icon: 'index', affix: true, isShow: 0 },
        children: [
            {
                path: '/index',
                component: () => import('@/views/index/index'),
                name: 'indexIndex',
                meta: { title: '首页', classify: 'index', icon: 'index', affix: true, isShow: 0}
            }
        ]
    },*/
]

const toolsRoutes1 = filterByKey([
    {
        path: '/',
        component: platform,
        redirect: '/home',
        name: "home",
        meta: {title: '首页', classify: 'home', icon: 'home', affix: true, level: 5, isShow: 1, authClassify: "home"},
        children: [
            {
                path: '/home',
                component: () => import('@/views/home/index'),
                name: 'homeIndex',
                meta: {
                    title: '首页',
                    classify: 'home',
                    icon: 'home',
                    affix: true,
                    level: 5,
                    isShow: 1,
                    authClassify: "home"
                }
            }
        ]
    },
    {
        path: '/nav',
        component: platform,
        redirect: '/nav/index',
        name: "nav",
        meta: {title: '导航管理', classify: 'nav', icon: 'nav', affix: true, level: 5, isShow: 1, authClassify: "nav"},
        children: [
            {
                path: 'index',
                component: () => import('@/views/nav/index'),
                name: 'navIndex',
                meta: {
                    title: '导航管理',
                    classify: 'nav',
                    icon: 'nav',
                    affix: true,
                    level: 4,
                    isShow: 1,
                    authClassify: "nav"
                }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/nav/detail.vue'),
                name: 'navDetail',
                meta: {
                    title: '导航详情',
                    classify: 'nav',
                    icon: 'nav',
                    affix: true,
                    level: 4,
                    isShow: 1,
                    authClassify: "nav"
                }
            },
        ]
    },
])

const goodsRoutes = filterByKey([
    {
        path: '/goodsCategory',
        component: platform,
        redirect: '/goodsCategory/index',
        name: "goodsCategory",
        meta: {
            title: '商品分类',
            classify: 'goodsCategory',
            icon: 'goodsCategory',
            affix: true,
            role: 4,
            level: 4,
            isShow: 1,
            authClassify: "goodsCategory"
        },
        children: [
            {
                path: 'index',
                component: () => import('@/views/goodsCategory/index'),
                name: 'goodsCategoryIndex',
                meta: {
                    title: '商品分类',
                    classify: 'goodsCategory',
                    icon: 'goodsCategory',
                    affix: true,
                    role: 4,
                    level: 4,
                    isShow: 1,
                    authClassify: "goodsCategory"
                }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/goodsCategory/detail.vue'),
                name: 'goodsCategoryDetail',
                meta: {
                    title: '商品分类详情',
                    classify: 'goodsCategory',
                    icon: 'goodsCategory',
                    affix: true,
                    role: 4,
                    level: 4,
                    isShow: 1,
                    authClassify: "goodsCategory"
                }
            },
        ]
    },
    {
        path: '/goods',
        component: platform,
        redirect: '/goods/list/0',
        name: "goods",
        meta: {
            title: '商品列表',
            classify: 'goods',
            icon: 'goods',
            affix: true,
            role: 4,
            level: 4,
            isShow: 1,
            authClassify: "goodsList"
        },
        children: [
            {
                path: 'list/:cid',
                component: () => import('@/views/goods/index'),
                name: 'goodsList',
                meta: {
                    title: '商品列表',
                    classify: 'goods',
                    icon: 'goods',
                    affix: true,
                    role: 4,
                    level: 4,
                    isShow: 1,
                    authClassify: "goodsList"
                }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/goods/detail'),
                name: 'goodsDetail',
                meta: {
                    title: '商品详情',
                    classify: 'goods',
                    icon: 'goods',
                    affix: true,
                    role: 4,
                    level: 4,
                    isShow: 1,
                    authClassify: "goodsList"
                }
            },
            {
                path: 'specifications/:cid/:id',
                component: () => import('@/views/goods/specifications'),
                name: 'specifications',
                meta: {
                    title: '规格设置',
                    classify: 'goods',
                    icon: 'goods',
                    affix: true,
                    role: 4,
                    level: 4,
                    isShow: 0,
                    authClassify: "goodsList"
                }
            },
        ]
    },
    {
        path: '/goodsSpecial',
        component: platform,
        redirect: '/goodsSpecial/index',
        name: "goodsSpecial",
        meta: {
            title: '商品专题',
            classify: 'goodsSpecial',
            icon: 'goodsSpecial',
            affix: true,
            role: 4,
            level: 4,
            isShow: 1
        },
        children: [
            {
                path: 'index',
                component: () => import('@/views/goodsSpecial/index'),
                name: 'goodsSpecialIndex',
                meta: {
                    title: '商品专题',
                    classify: 'goodsSpecial',
                    icon: 'goodsSpecial',
                    affix: true,
                    role: 4,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/goodsSpecial/detail.vue'),
                name: 'goodsSpecialDetail',
                meta: {
                    title: '商品专题',
                    classify: 'goodsSpecial',
                    icon: 'goodsSpecial',
                    affix: true,
                    role: 4,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'addGoods/:cid',
                component: () => import('@/views/goodsSpecial/addGoods.vue'),
                name: 'addGoodsSpecial',
                meta: {
                    title: '添加专题商品',
                    classify: 'goodsSpecial',
                    icon: 'goodsSpecial',
                    affix: true,
                    role: 4,
                    level: 4,
                    isShow: 1
                }
            },
        ]
    },
    {
        path: '/goodsPackage',
        component: platform,
        redirect: '/goodsPackage/index',
        name: "goodsPackage",
        meta: {
            title: '商品礼包',
            classify: 'goodsPackage',
            icon: 'goodsPackage',
            affix: true,
            role: 4,
            level: 4,
            isShow: 0
        },
        children: [
            {
                path: 'index',
                component: () => import('@/views/goodsPackage/index'),
                name: 'goodsPackageIndex',
                meta: {
                    title: '商品礼包',
                    classify: 'goodsPackage',
                    icon: 'goodsPackage',
                    affix: true,
                    role: 4,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/goodsPackage/detail.vue'),
                name: 'goodsPackageDetail',
                meta: {
                    title: '商品礼包',
                    classify: 'goodsPackage',
                    icon: 'goodsPackage',
                    affix: true,
                    role: 4,
                    level: 4,
                    isShow: 1
                }
            },
        ]
    },
]);

const articleRoutes = filterByKey([
    {
        path: '/notice',
        component: platform,
        redirect: '/notice/index',
        name: "notice",
        meta: {
            title: '通知管理',
            classify: 'notice',
            icon: 'notice',
            affix: true,
            role: 4,
            level: 5,
            isShow: 1,
            authClassify: "notice"
        },
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/notice/index'),
                name: 'noticeIndex',
                meta: {
                    title: '通知管理',
                    classify: 'notice',
                    icon: 'notice',
                    affix: true,
                    role: 4,
                    level: 5,
                    isShow: 1,
                    authClassify: "notice"
                }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/notice/detail'),
                name: 'noticeTable',
                meta: {
                    title: '通知管理',
                    classify: 'notice',
                    icon: 'notice',
                    affix: true,
                    role: 4,
                    level: 5,
                    isShow: 1,
                    authClassify: "notice"
                }
            }
        ])
    },
    {
        path: '/systemMessages',
        component: platform,
        redirect: '/systemMessages/index',
        name: "systemMessages",
        meta: {
            title: '系统消息',
            classify: 'systemMessages',
            icon: 'systemMessages',
            affix: true,
            role: 4,
            level: 5,
            isShow: 1,
            authClassify: "systemMessages"
        },
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/systemMessages/index'),
                name: 'systemMessagesIndex',
                meta: {
                    title: '系统消息',
                    classify: 'systemMessages',
                    icon: 'systemMessages',
                    affix: true,
                    role: 4,
                    level: 5,
                    isShow: 1,
                    authClassify: "systemMessages"
                }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/systemMessages/detail'),
                name: 'systemMessagesTable',
                meta: {
                    title: '系统消息',
                    classify: 'systemMessages',
                    icon: 'systemMessages',
                    affix: true,
                    role: 4,
                    level: 5,
                    isShow: 1,
                    authClassify: "systemMessages"
                }
            }
        ])
    },
    {
        path: '/slideshow',
        component: platform,
        redirect: '/slideshow/index',
        name: "slideshow",
        meta: {
            title: '轮播图管理',
            classify: 'slideshow',
            icon: 'slideshow',
            affix: true,
            role: 4,
            level: 5,
            isShow: 1,
            authClassify: "slideshow"
        },
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/slideshow/index'),
                name: 'slideshowIndex',
                meta: {
                    title: '轮播图管理',
                    classify: 'slideshow',
                    icon: 'slideshow',
                    affix: true,
                    role: 4,
                    level: 5,
                    isShow: 1,
                    authClassify: "slideshow"
                }
            },
        ])
    },
    {
        path: '/category',
        component: platform,
        redirect: '/category/index',
        name: "category",
        meta: {title: '文章分类', classify: 'category', icon: 'category', affix: true, role: 4, level: 5, isShow: 1},
        children: [
            {
                path: 'index',
                component: () => import('@/views/category/index'),
                name: 'categoryIndex',
                meta: {title: '文章分类', classify: 'category', icon: 'category', affix: true, role: 4, level: 5, isShow: 1}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/category/detail.vue'),
                name: 'categoryDetail',
                meta: {title: '分类详情', classify: 'category', icon: 'category', affix: true, role: 4, level: 5, isShow: 1}
            },
        ]
    },
    {
        path: '/article',
        component: platform,
        redirect: '/article/list/0',
        name: "article",
        meta: {title: '文章列表', classify: 'article', icon: 'article', affix: true, role: 4, level: 5, isShow: 1},
        children: [
            {
                path: 'list/:cid',
                component: () => import('@/views/article/index'),
                name: 'articleList',
                meta: {title: '文章列表', classify: 'article', icon: 'article', affix: true, role: 4, level: 5, isShow: 1}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/article/detail'),
                name: 'articleDetail',
                meta: {title: '文章详情', classify: 'article', icon: 'article', affix: true, role: 4, level: 5, isShow: 1}
            },
        ]
    },
    {
        path: '/tags',
        component: platform,
        redirect: '/tags',
        name: "tags",
        meta: {title: 'TAGS 列表', classify: 'tags', icon: 'tags', affix: true, role: 4, level: 5, isShow: 0},
        children: [
            {
                path: '/tags',
                component: () => import('@/views/tags/index'),
                name: 'tagsIndex',
                meta: {title: 'TAGS 列表', classify: 'tags', icon: 'tags', affix: true, role: 4, level: 5, isShow: 0}
            }
        ]
    },
    {
        path: '/applyForm',
        component: platform,
        redirect: '/applyForm/index',
        name: "applyForm",
        meta: {title: '表单列表', classify: 'applyForm', icon: 'applyForm', affix: true, role: 4, level: 5, isShow: 0},
        children: [
            {
                path: 'index',
                component: () => import('@/views/applyForm/index'),
                name: 'applyFormIndex',
                meta: {
                    title: '表单列表',
                    classify: 'applyForm',
                    icon: 'applyForm',
                    affix: true,
                    role: 4,
                    level: 5,
                    isShow: 0
                }
            },
            {
                path: 'classify',
                component: () => import('@/views/applyForm/classify'),
                name: 'applyFormClassify',
                meta: {
                    title: '表单分类',
                    classify: 'applyFormClassify',
                    icon: 'applyFormClassify',
                    affix: true,
                    role: 4,
                    level: 5,
                    isShow: 0
                }
            }
        ]
    },
]);

const teamRoutes = filterByKey([
    {
        path: '/team',
        component: platform,
        redirect: '/team/index',
        name: "team",
        meta: {title: '团队管理', classify: 'team', icon: 'team', affix: true, level: 3, role: 2, table: 'team', isShow: 1},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/team/index'),
                name: 'teamIndex',
                meta: {
                    title: '团队管理',
                    classify: 'team',
                    icon: 'team',
                    affix: true,
                    table: 'team',
                    isShow: 1,
                    level: 3,
                    role: 2
                }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/team/detail'),
                name: 'teamDetail',
                meta: {
                    title: '团队详情',
                    classify: 'team',
                    icon: 'team',
                    affix: true,
                    table: 'team',
                    isShow: 1,
                    level: 3,
                    role: 2
                }
            },
        ])
    },
    {
        path: '/admin',
        component: platform,
        redirect: '/admin/index',
        name: "admin",
        meta: {title: '团队管理员', classify: 'admin', icon: 'admin', affix: true, level: 3, role: 2, isShow: 1},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/admin/index'),
                name: 'adminIndex',
                meta: {title: '管理员管理', classify: 'admin', icon: 'admin', affix: true, level: 3, role: 2, isShow: 1}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/admin/detail'),
                name: 'adminDetail',
                meta: {title: '管理员详情', classify: 'admin', icon: 'admin', affix: true, level: 3, role: 2, isShow: 1}
            }
        ])
    },
])

const distributorRoutes = filterByKey([
    {
        path: '/distributor',
        component: platform,
        redirect: '/distributor/index',
        name: "distributor",
        meta: {title: '分销商管理', classify: 'distributor', icon: 'distributor', affix: true, level: 3, role: 2, table: 'distributor', isShow: 1},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/distributor/index'),
                name: 'distributorIndex',
                meta: {
                    title: '分销商管理',
                    classify: 'distributor',
                    icon: 'distributor',
                    affix: true,
                    table: 'distributor',
                    isShow: 1,
                    level: 3,
                    role: 2
                }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/distributor/detail'),
                name: 'distributorDetail',
                meta: {
                    title: '分销商详情',
                    classify: 'distributor',
                    icon: 'distributor',
                    affix: true,
                    table: 'distributor',
                    isShow: 1,
                    level: 3,
                    role: 2
                }
            },
        ])
    },
    {
        path: '/admin',
        component: platform,
        redirect: '/admin/index',
        name: "admin",
        meta: {title: '团队管理员', classify: 'admin', icon: 'admin', affix: true, level: 3, role: 2, isShow: 1},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/admin/index'),
                name: 'adminIndex',
                meta: {title: '管理员管理', classify: 'admin', icon: 'admin', affix: true, level: 3, role: 2, isShow: 1}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/admin/detail'),
                name: 'adminDetail',
                meta: {title: '管理员详情', classify: 'admin', icon: 'admin', affix: true, level: 3, role: 2, isShow: 1}
            }
        ])
    },
])

const orderRoutes = filterByKey([
    {
        path: '/goodsOrder',
        component: platform,
        redirect: '/goodsOrder/index',
        name: "goodsOrder",
        meta: {
            title: '自购订单',
            classify: 'goodsOrder',
            icon: 'goodsOrder',
            table: 'goods_order',
            affix: true,
            role: 3,
            level: 4,
            isShow: 1
        },
        children: [
            {
                path: 'index',
                component: () => import('@/views/goodsOrder/index'),
                name: 'goodsOrderIndex',
                meta: {
                    title: '商品订单',
                    classify: 'goodsOrder',
                    icon: 'goodsOrder',
                    table: 'goods_order',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'detail/:order_id',
                component: () => import('@/views/goodsOrder/detail.vue'),
                name: 'goodsOrderDetail',
                meta: {
                    title: '商品订单详情',
                    classify: 'goodsOrder',
                    icon: 'goodsOrder',
                    table: 'goods_order',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'splitOrderAllList/',
                component: () => import('@/views/goodsOrder/splitOrderAllList.vue'),
                name: 'goodsOrderSplitOrderAllList',
                meta: {
                    title: '自选订单拆单列表',
                    classify: 'goodsOrder',
                    icon: 'goodsOrder',
                    table: 'goodsOrder',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'splitOrderList/:id',
                component: () => import('@/views/goodsOrder/splitOrderList.vue'),
                name: 'goodsOrderSplitOrderList',
                meta: {
                    title: '自选订单拆单列表',
                    classify: 'goodsOrder',
                    icon: 'goodsOrder',
                    table: 'goodsOrder',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'splitOrderDetailZY/:id/:type',
                component: () => import('@/views/goodsOrder/splitOrderDetailZY.vue'),
                name: 'goodsOrderSplitOrderDetailZY',
                meta: {
                    title: '自选订单拆单详情',
                    classify: 'goodsOrder',
                    icon: 'goodsOrder',
                    table: 'goodsOrder',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'splitOrderDetail/:id/:type',
                component: () => import('@/views/goodsOrder/splitOrderDetail.vue'),
                name: 'goodsOrderSplitOrderDetail',
                meta: {
                    title: '自选订单拆单详情',
                    classify: 'goodsOrder',
                    icon: 'goodsOrder',
                    table: 'goodsOrder',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'splitOrderAferSale/:id/:type',
                component: () => import('@/views/goodsOrder/splitOrderAferSale.vue'),
                name: 'goodsOrderSplitOrderAferSale',
                meta: {
                    title: '自选订单拆单售后详情',
                    classify: 'goodsOrder',
                    icon: 'goodsOrder',
                    table: 'goodsOrder',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'splitOrderLogistics/:id',
                component: () => import('@/views/goodsOrder/splitOrderLogistics.vue'),
                name: 'splitOrderLogisticsZXId',
                meta: {
                    title: '物流信息',
                    classify: 'goodsOrder',
                    icon: 'goodsOrder',
                    table: 'goodsOrder',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'logisticsDetail/:sn',
                component: () => import('@/views/goodsOrder/logisticsDetail.vue'),
                name: 'logisticsDetailZSSN',
                meta: {
                    title: '物流信息',
                    classify: 'goodsOrder',
                    icon: 'goodsOrder',
                    group: 'goodsOrder',
                    table: 'goodsOrder',
                    auditStatus: 1,
                    isOpen: false,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'logisticsDetailZY/:sn',
                component: () => import('@/views/goodsOrder/logisticsDetailZY.vue'),
                name: 'logisticsDetailZSSNZY',
                meta: {
                    title: '物流信息',
                    classify: 'goodsOrder',
                    icon: 'goodsOrder',
                    group: 'goodsOrder',
                    table: 'goodsOrder',
                    auditStatus: 1,
                    isOpen: false,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
        ]
    },
    {
        path: '/withdrawal',
        component: platform,
        redirect: '/withdrawal/index',
        name: "withdrawal",
        meta: {
            title: '换钱订单',
            classify: 'withdrawal',
            icon: 'withdrawal',
            table: 'user_withdrawal_record',
            affix: true,
            role: 3,
            level: 4,
            isShow: 1
        },
        children: [
            {
                path: 'index',
                component: () => import('@/views/withdrawal/index'),
                name: 'withdrawalIndex',
                meta: {
                    title: '换钱订单',
                    classify: 'withdrawal',
                    icon: 'withdrawal',
                    table: 'user_withdrawal_record',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'detail/:order_id',
                component: () => import('@/views/withdrawal/detail.vue'),
                name: 'withdrawalDetail',
                meta: {
                    title: '换钱订单详情',
                    classify: 'withdrawal',
                    icon: 'withdrawal',
                    table: 'user_withdrawal_record',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
        ]
    },
    {
        path: '/cardPay',
        component: platform,
        redirect: '/cardPay/index',
        name: "cardPay",
        meta: {
            title: '充值卡充值订单',
            classify: 'cardPay',
            icon: 'cardPay',
            table: 'recharge_card_pay',
            role: 3,
            level: 4,
            affix: true,
            isShow: 1
        },
        children: [
            {
                path: 'index',
                component: () => import('@/views/cardPay/index'),
                name: 'cardPayIndex',
                meta: {
                    title: '充值卡充值订单',
                    classify: 'cardPay',
                    icon: 'cardPay',
                    table: 'recharge_card_pay',
                    role: 3,
                    level: 4,
                    affix: true,
                    isShow: 1
                }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/cardPay/detail.vue'),
                name: 'cardPayDetail',
                meta: {
                    title: '充值卡充值订单详情',
                    classify: 'cardPay',
                    icon: 'cardPay',
                    role: 3,
                    level: 4,
                    affix: true,
                    isShow: 1
                }
            },
        ]
    },
    {
        path: '/bagOrder',
        component: platform,
        redirect: '/bagOrder/index',
        name: "bagOrder",
        meta: {
            title: '福利礼包订单',
            classify: 'bagOrder',
            icon: 'bagOrder',
            table: 'goods_package_pay',
            role: 3,
            level: 4,
            affix: true,
            isShow: 1
        },
        children: [
            {
                path: 'index',
                component: () => import('@/views/bagOrder/index'),
                name: 'bagOrderIndex',
                meta: {
                    title: '福利礼包支付订单',
                    classify: 'bagOrder',
                    icon: 'bagOrder',
                    table: 'goods_package_pay',
                    role: 3,
                    level: 4,
                    affix: true,
                    isShow: 1
                }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/bagOrder/detail.vue'),
                name: 'bagOrderDetail',
                meta: {
                    title: '福利礼包支付订单详情',
                    classify: 'bagOrder',
                    icon: 'bagOrder',
                    role: 3,
                    level: 4,
                    affix: true,
                    isShow: 1
                }
            },
            {
                path: 'orderDetail/:order_id',
                component: () => import('@/views/bagOrder/orderDetail.vue'),
                name: 'orderDetail',
                meta: {
                    title: '福利礼包订单详情',
                    classify: 'bagOrder',
                    icon: 'bagOrder',
                    role: 3,
                    level: 4,
                    affix: true,
                    isShow: 1
                }
            },
        ]
    },
    {
        path: '/extractOrder',
        component: platform,
        redirect: '/extractOrder/index',
        name: "extractOrder",
        meta: {
            title: '礼包提货订单',
            classify: 'extractOrder',
            icon: 'extractOrder',
            table: 'goods_package_order',
            affix: true,
            role: 3,
            level: 4,
            isShow: 1
        },
        children: [
            {
                path: 'index',
                component: () => import('@/views/extractOrder/index'),
                name: 'extractOrderIndex',
                meta: {
                    title: '礼包提货订单',
                    classify: 'extractOrder',
                    icon: 'extractOrder',
                    table: 'user_extract_record_1',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'detail/:order_id',
                component: () => import('@/views/extractOrder/detail.vue'),
                name: 'extractOrderDetail',
                meta: {
                    title: '礼包提货订单详情',
                    classify: 'extractOrder',
                    icon: 'extractOrder',
                    table: 'extractOrder',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'splitOrderAllList/',
                component: () => import('@/views/extractOrder/splitOrderAllList.vue'),
                name: 'extractOrderSplitOrderAllList',
                meta: {
                    title: '礼包提货订单拆单列表',
                    classify: 'extractOrder',
                    icon: 'extractOrder',
                    table: 'extractOrder',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'splitOrderList/:id',
                component: () => import('@/views/extractOrder/splitOrderList.vue'),
                name: 'extractOrderSplitOrderList',
                meta: {
                    title: '礼包提货订单拆单列表',
                    classify: 'extractOrder',
                    icon: 'extractOrder',
                    table: 'extractOrder',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'splitOrderDetail/:id/:type',
                component: () => import('@/views/extractOrder/splitOrderDetail.vue'),
                name: 'extractOrderSplitOrderDetail',
                meta: {
                    title: '礼包提货订单拆单详情',
                    classify: 'extractOrder',
                    icon: 'extractOrder',
                    table: 'extractOrder',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'splitOrderDetailZY/:id/:type',
                component: () => import('@/views/extractOrder/splitOrderDetailZY.vue'),
                name: 'extractOrderSplitOrderDetailZY',
                meta: {
                    title: '礼包提货订单拆单详情',
                    classify: 'extractOrder',
                    icon: 'extractOrder',
                    table: 'extractOrder',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'splitOrderLogistics/:id/',
                component: () => import('@/views/extractOrder/splitOrderLogistics.vue'),
                name: 'splitOrderLogisticsZX',
                meta: {
                    title: '物流信息',
                    classify: 'extractOrder',
                    icon: 'extractOrder',
                    table: 'extractOrder',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'logisticsDetail/:sn',
                component: () => import('@/views/extractOrder/logisticsDetail.vue'),
                name: 'logisticsDetailZS',
                meta: {
                    title: '物流信息',
                    classify: 'extractOrder',
                    icon: 'extractOrder',
                    group: 'extractOrder',
                    table: 'extractOrder',
                    auditStatus: 1,
                    isOpen: false,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'logisticsDetailZY/:sn',
                component: () => import('@/views/extractOrder/logisticsDetailZY.vue'),
                name: 'logisticsDetailSZY',
                meta: {
                    title: '物流信息',
                    classify: 'extractOrder',
                    icon: 'extractOrder',
                    group: 'extractOrder',
                    table: 'extractOrder',
                    auditStatus: 1,
                    isOpen: false,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
        ]
    },
    {
        path: '/extractBean',
        component: platform,
        redirect: '/extractBean/index',
        name: "extractBean",
        meta: {
            title: '礼包换豆订单',
            classify: 'extractBean',
            icon: 'extractBean',
            table: 'user_extract_record_3',
            affix: true,
            role: 3,
            level: 4,
            isShow: 1,
            authClassify: "extractBean"
        },
        children: [
            {
                path: 'index',
                component: () => import('@/views/extractBean/index'),
                name: 'extractBeanIndex',
                meta: {
                    title: '礼包换豆订单',
                    classify: 'extractBean',
                    icon: 'extractBean',
                    table: 'user_extract_record_3',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'detail/:order_id',
                component: () => import('@/views/extractBean/detail.vue'),
                name: 'extractBeanDetail',
                meta: {
                    title: '礼包换豆订单详情',
                    classify: 'extractBean',
                    icon: 'extractBean',
                    table: 'extractBean',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
        ]
    },
    {
        path: '/extractWithdrawal',
        component: platform,
        redirect: '/extractWithdrawal/index',
        name: "extractWithdrawal",
        meta: {
            title: '礼包换钱订单',
            classify: 'extractWithdrawal',
            icon: 'extractWithdrawal',
            table: 'user_extract_record_2',
            affix: true,
            role: 3,
            level: 4,
            isShow: 1,
            authClassify: "extractWithdrawal"
        },
        children: [
            {
                path: 'index',
                component: () => import('@/views/extractWithdrawal/index'),
                name: 'extractWithdrawalIndex',
                meta: {
                    title: '礼包换钱订单',
                    classify: 'extractWithdrawal',
                    icon: 'extractWithdrawal',
                    table: 'user_extract_record_2',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'detail/:order_id',
                component: () => import('@/views/extractWithdrawal/detail.vue'),
                name: 'extractWithdrawalDetail',
                meta: {
                    title: '礼包换钱订单详情',
                    classify: 'extractWithdrawal',
                    icon: 'extractWithdrawal',
                    table: 'extractWithdrawal',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
        ]
    },
    {
        path: '/employeesOrder',
        component: platform,
        redirect: '/employeesOrder/index',
        name: "employeesOrder",
        meta: {
            title: '员工充值订单',
            classify: 'employeesOrder',
            icon: 'employeesOrder',
            table: 'recharge_order',
            role: 3,
            level: 4,
            affix: true,
            isShow: 1
        },
        children: [
            {
                path: 'index',
                component: () => import('@/views/employeesOrder/index'),
                name: 'employeesOrderIndex',
                meta: {
                    title: '员工福利订单',
                    classify: 'employeesOrder',
                    icon: 'employeesOrder',
                    table: 'recharge_order',
                    role: 3,
                    level: 4,
                    affix: true,
                    isShow: 1
                }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/employeesOrder/detail.vue'),
                name: 'employeesOrderDetail',
                meta: {
                    title: '员工福利订单详情',
                    classify: 'employeesOrder',
                    icon: 'employeesOrder',
                    role: 3,
                    level: 4,
                    affix: true,
                    isShow: 1
                }
            },
        ]
    },
    {
        path: '/invoice',
        component: platform,
        redirect: '/invoice/index',
        name: "invoice",
        meta: {
            title: '报销凭证订单',
            classify: 'invoice',
            icon: 'invoice',
            table: 'invoice_order',
            role: 3,
            level: 4,
            affix: true,
            isShow: 1
        },
        children: [
            {
                path: 'index',
                component: () => import('@/views/invoice/index'),
                name: 'invoiceOrderIndex',
                meta: {
                    title: '报销凭证列表',
                    classify: 'invoice',
                    icon: 'invoice',
                    table: 'invoice_order',
                    role: 3,
                    level: 4,
                    affix: true,
                    isShow: 1
                }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/invoice/detail.vue'),
                name: 'invoiceDetail',
                meta: {
                    title: '报销凭证订单详情',
                    classify: 'invoice',
                    icon: 'invoice',
                    role: 3,
                    level: 4,
                    affix: true,
                    isShow: 1,
                    table: 'invoice_order',
                }
            },
            {
                path: 'orderDetail/:order_id',
                component: () => import('@/views/invoice/orderDetail.vue'),
                name: 'invoiceOrderDetail',
                meta: {
                    title: '报销凭证商品订单详情',
                    classify: 'invoice',
                    icon: 'invoice',
                    role: 3,
                    level: 4,
                    affix: true,
                    isShow: 1
                }
            },
        ]
    },
    {
        path: '/goodsOrderPay',
        component: platform,
        redirect: '/goodsOrderPay/index',
        name: "goodsOrderPay",
        meta: {
            title: '采购订单',
            classify: 'goodsOrderPay',
            icon: 'goodsOrderPay',
            table: 'goods_order_po_pay',
            role: 3,
            level: 4,
            affix: true,
            isShow: 1
        },
        children: [
            {
                path: 'index',
                component: () => import('@/views/goodsOrderPay/index'),
                name: 'goodsOrderPayIndex',
                meta: {
                    title: '采购订单',
                    classify: 'goodsOrderPay',
                    icon: 'goodsOrderPay',
                    table: 'goods_order_po_pay',
                    role: 3,
                    affix: true,
                    isShow: 1
                }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/goodsOrderPay/detail.vue'),
                name: 'goodsOrderPayDetail',
                meta: {
                    title: '采购订单详情',
                    classify: 'goodsOrderPay',
                    icon: 'goodsOrderPay',
                    table: 'goods_order_po_pay',
                    role: 3,
                    affix: true,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'detailOne/:order_id',
                component: () => import('@/views/goodsOrderPay/detailOne.vue'),
                name: 'goodsOrderPayDetailOne',
                meta: {
                    title: '采购订单详情',
                    classify: 'goodsOrderPay',
                    icon: 'goodsOrderPay',
                    table: 'goods_order_po_pay',
                    role: 3,
                    affix: true,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'splitOrder/:id',
                component: () => import('@/views/goodsOrderPay/splitOrder.vue'),
                name: 'goodsOrderPaySplitOrder',
                meta: {
                    title: '采购订单拆单',
                    classify: 'goodsOrderPay',
                    icon: 'goodsOrderPay',
                    table: 'goods_order_po_pay',
                    role: 3,
                    affix: true,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'splitOrderAllList',
                component: () => import('@/views/goodsOrderPay/splitOrderAllList.vue'),
                name: 'goodsOrderPaySplitOrderAllList',
                meta: {
                    title: '采购订单拆单列表',
                    classify: 'goodsOrderPay',
                    icon: 'goodsOrderPay',
                    table: 'goods_order_po_pay',
                    role: 3,
                    affix: true,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'splitOrderList/:id',
                component: () => import('@/views/goodsOrderPay/splitOrderList.vue'),
                name: 'goodsOrderPaySplitOrderList',
                meta: {
                    title: '采购订单拆单列表',
                    classify: 'goodsOrderPay',
                    icon: 'goodsOrderPay',
                    table: 'goods_order_po_pay',
                    role: 3,
                    affix: true,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'splitOrderDetail/:id/:type',
                component: () => import('@/views/goodsOrderPay/splitOrderDetail.vue'),
                name: 'goodsOrderPaySplitOrderDetail',
                meta: {
                    title: '采购订单拆单详情',
                    classify: 'goodsOrderPay',
                    icon: 'goodsOrderPay',
                    table: 'goods_order_po_pay',
                    role: 3,
                    affix: true,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'splitOrderLogistics/:id/',
                component: () => import('@/views/goodsOrderPay/splitOrderLogistics.vue'),
                name: 'splitOrderLogistics',
                meta: {
                    title: '物流信息',
                    classify: 'splitOrderLogistics',
                    icon: 'splitOrderLogistics',
                    table: 'splitOrderLogistics',
                    role: 3,
                    affix: true,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'logisticsDetail/:sn',
                component: () => import('@/views/goodsOrderPay/logisticsDetail.vue'),
                name: 'logisticsDetail',
                meta: {
                    title: '物流信息',
                    classify: 'vpoOrder',
                    icon: 'vpoOrder',
                    group: 'admin',
                    table: 'goods_order_po',
                    auditStatus: 1,
                    role: 3,
                    isOpen: false,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'logisticsDetail/:sn',
                component: () => import('@/views/goodsOrderPay/logisticsDetail.vue'),
                name: 'logisticsDetail',
                meta: {
                    title: '物流信息',
                    classify: 'vpoOrder',
                    icon: 'vpoOrder',
                    group: 'admin',
                    table: 'goods_order_po',
                    auditStatus: 1,
                    role: 3,
                    isOpen: false,
                    level: 4,
                    isShow: 1
                }
            },
        ]
    },
    {
        path: '/refundOrderPay',
        component: platform,
        redirect: '/refundOrderPay/index',
        name: "refundOrderPay",
        meta: {
            title: '采购退货订单',
            classify: 'refundOrderPay',
            icon: 'refundOrderPay',
            table: 'goods_order_po_refund',
            affix: true,
            role: 3,
            level: 4,
            isShow: 1
        },
        children: [
            {
                path: 'index',
                component: () => import('@/views/refundOrderPay/index'),
                name: 'refundOrderPayIndex',
                meta: {
                    title: '采购退货订单',
                    classify: 'refundOrderPay',
                    icon: 'refundOrderPay',
                    table: 'goods_order_po_refund',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/refundOrderPay/detail.vue'),
                name: 'refundOrderPayDetail',
                meta: {
                    title: '采购退货订单详情',
                    classify: 'refundOrderPay',
                    icon: 'refundOrderPay',
                    table: 'refund_order_po_pay',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            }
        ]
    },
    {
        path: '/returnProductsUser',
        component: platform,
        redirect: '/returnProductsUser/index',
        name: "returnProductsUser",
        meta: {
            title: '自购退货订单',
            classify: 'returnProductsUser',
            icon: 'returnProductsUser',
            table: 'return_products_user',
            affix: true,
            role: 3,
            level: 4,
            isShow: 1
        },
        children: [
            {
                path: 'index',
                component: () => import('@/views/returnProductsUser/index'),
                name: 'returnProductsUserIndex',
                meta: {
                    title: '自选退货订单',
                    classify: 'returnProductsUser',
                    icon: 'returnProductsUser',
                    table: 'return_products_user',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/returnProductsUser/detail'),
                name: 'returnProductsUserDetail',
                meta: {
                    title: '自选退货订单详情',
                    classify: 'returnProductsUser',
                    icon: 'returnProductsUser',
                    table: 'refund_order_po_pay',
                    affix: true,
                    role: 3,
                    level: 4,
                    isShow: 1
                }
            }
        ]
    },
]);

const financialRoutes = filterByKey([
    {
        path: '/financial/goods',
        component: platform,
        redirect: '/financial/goods/index',
        name: "financialGoods",
        meta: {
            title: '商品对账',
            classify: 'financialGoods',
            icon: 'financialGoods',
            affix: true,
            level: 3,
            role: 2,
            table: 'goods_details_list',
            isShow: 1
        },
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/financial/goods/index'),
                name: 'financialGoodsIndex',
                meta: {
                    title: '商品对账',
                    classify: 'financialGoods',
                    icon: 'financialGoods',
                    affix: true,
                    table: 'goods_details_list',
                    isShow: 1,
                    level: 3,
                    role: 2
                }
            },
        ])
    },
    {
        path: '/financial/generalLedgerIn',
        component: platform,
        redirect: '/financial/generalLedgerIn/index',
        name: "generalLedgerIn",
        meta: {
            title: '总入账',
            classify: 'generalLedgerIn',
            icon: 'generalLedgerIn',
            affix: true,
            level: 3,
            role: 2,
            table: 'team',
            isShow: 1
        },
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/financial/generalLedgerIn/index'),
                name: 'index',
                meta: {
                    title: '总入账',
                    classify: 'generalLedgerIn',
                    icon: 'generalLedgerIn',
                    affix: true,
                    table: 'general_ledger_in',
                    isShow: 1,
                    level: 3,
                    role: 2
                }
            },
        ])
    },
    {
        path: '/financial/generalLedgerOut',
        component: platform,
        redirect: '/financial/generalLedgerOut/index',
        name: "generalLedgerOut",
        meta: {
            title: '总出账',
            classify: 'generalLedgerOut',
            icon: 'generalLedgerOut',
            affix: true,
            level: 3,
            role: 2,
            table: 'team',
            isShow: 1
        },
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/financial/generalLedgerOut/index'),
                name: 'generalLedgerOutIndex',
                meta: {
                    title: '总出账',
                    classify: 'generalLedgerOut',
                    icon: 'generalLedgerOut',
                    affix: true,
                    table: 'general_ledger_out',
                    isShow: 1,
                    level: 3,
                    role: 2
                }
            },
        ])
    },
    {
        path: '/recharge',
        component: platform,
        redirect: '/recharge/index',
        name: "recharge",
        meta: {
            title: '在线充值记录',
            classify: 'recharge',
            icon: 'recharge',
            table: 'user_recharge_rmb',
            role: 3,
            level: 4,
            affix: true,
            isShow: 1
        },
        children: [
            {
                path: 'index',
                component: () => import('@/views/recharge/index'),
                name: 'rechargeIndex',
                meta: {
                    title: '在线充值记录',
                    classify: 'recharge',
                    icon: 'recharge',
                    table: 'user_recharge_rmb',
                    role: 3,
                    level: 4,
                    affix: true,
                    isShow: 1
                }
            }
        ]
    },
    {
        path: '/goodsOrderAll',
        component: platform,
        redirect: '/goodsOrderAll/index',
        name: "goodsOrderAll",
        meta: {
            title: '自选订单记录',
            classify: 'goodsOrderAll',
            icon: 'goodsOrderAll',
            table: 'goods_order',
            role: 3,
            level: 4,
            affix: true,
            isShow: 1
        },
        children: [
            {
                path: 'index',
                component: () => import('@/views/goodsOrder/all'),
                name: 'goodsOrderAllIndex',
                meta: {
                    title: '自选订单记录',
                    classify: 'goodsOrderAll',
                    icon: 'goodsOrderAll',
                    table: 'goods_order',
                    role: 3,
                    level: 4,
                    affix: true,
                    isShow: 1
                }
            }
        ]
    },
    {
        path: '/rechargeCard',
        component: platform,
        redirect: '/rechargeCard/index',
        name: "rechargeCard",
        meta: {
            title: '充值卡记录',
            classify: 'rechargeCard',
            icon: 'rechargeCard',
            table: 'recharge_card',
            role: 3,
            level: 4,
            affix: true,
            isShow: 1
        },
        children: [
            {
                path: 'index',
                component: () => import('@/views/rechargeCard/index'),
                name: 'rechargeCard',
                meta: {
                    title: '充值卡记录',
                    classify: 'rechargeCard',
                    icon: 'rechargeCard',
                    table: 'recharge_card',
                    role: 3,
                    level: 4,
                    affix: true,
                    isShow: 1
                }
            }
        ]
    },
    {
        path: '/user',
        component: platform,
        redirect: '/user/index',
        name: "user",
        meta: {
            title: '所有注册用户',
            classify: 'user',
            icon: 'user',
            table: 'user',
            role: 3,
            level: 4,
            affix: true,
            isShow: 1
        },
        children: [
            {
                path: 'index',
                component: () => import('@/views/user/index'),
                name: 'user',
                meta: {
                    title: '所有注册用户',
                    classify: 'user',
                    icon: 'user',
                    table: 'user',
                    role: 3,
                    level: 4,
                    affix: true,
                    isShow: 1
                }
            }
        ]
    },
])

const toolsRoutes = filterByKey([
    {
        path: '/import',
        component: platform,
        redirect: '/import/index',
        name: "import",
        meta: {title: '导入数据', classify: 'import', icon: 'import', affix: true, level: 2, role: 2, isShow: 0},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/import/index'),
                name: 'importIndex',
                meta: {title: '导入数据', classify: 'import', icon: 'import', affix: true, level: 2, role: 2, isShow: 1}
            },
            {
                path: 'table',
                component: () => import('@/views/import/table'),
                name: 'importTable',
                meta: {title: '导入数据', classify: 'import', icon: 'import', affix: true, level: 2, role: 2, isShow: 1}
            }
        ])
    },
])

const providerRoutes = filterByKey([
    {
        path: '/provider',
        component: platform,
        redirect: '/provider/index',
        name: "provider",
        meta: {title: '供应商管理', classify: 'provider', icon: 'provider', affix: true, level: 3, role: 2, isShow: 1},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/provider/index'),
                name: 'providerIndex',
                meta: {title: '供应商', classify: 'provider', icon: 'provider', affix: true, level: 3, role: 2, isShow: 1}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/provider/detail'),
                name: 'providerDetail',
                meta: {
                    title: '供应商详情',
                    classify: 'provider',
                    icon: 'provider',
                    affix: true,
                    level: 3,
                    role: 2,
                    isShow: 1
                }
            }
        ])
    },
])

const systemRoutes = filterByKey([
    {
        path: '/boss',
        component: platform,
        redirect: '/boss/index',
        name: "boss",
        meta: {title: '平台管理员', classify: 'boss', icon: 'boss', affix: true, level: 2, role: 2, isShow: 1},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/boss/index'),
                name: 'bossIndex',
                meta: {title: '平台管理员', classify: 'boss', icon: 'boss', affix: true, level: 2, role: 2, isShow: 1}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/boss/detail'),
                name: 'bossDetail',
                meta: {title: '平台管理员详情', classify: 'boss', icon: 'boss', affix: true, level: 2, role: 2, isShow: 1}
            }
        ])
    },
    {
        path: '/auth',
        component: platform,
        redirect: '/auth/index/0',
        name: "auth",
        meta: {title: '授权管理', classify: 'auth', icon: 'auth', affix: true, level: 2, role: 2, isShow: 1},
        children: filterByKey([
            {
                path: 'index/:id',
                component: () => import('@/views/auth/index'),
                name: 'authIndex',
                meta: {title: '授权管理', classify: 'auth', icon: 'auth', affix: true, level: 2, role: 2, isShow: 1}
            },
            {
                path: 'options',
                component: () => import('@/views/auth/options'),
                name: 'authOptions',
                meta: {title: '分类选项管理', classify: 'auth', icon: 'auth', affix: true, level: 2, role: 2, isShow: 1}
            }
        ])
    },
    {
        path: '/system',
        component: platform,
        redirect: '/system/index',
        name: "system",
        meta: {title: '系统配置', classify: 'system', icon: 'system', affix: true, level: 2, role: 2, isShow: 1},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/system/index'),
                name: 'systemIndex',
                meta: {title: '系统配置', classify: 'system', icon: 'system', affix: true, level: 2, role: 2, isShow: 1}
            },
        ])
    },
    {
        path: '/collect',
        component: platform,
        redirect: '/collect/index',
        name: "collect",
        meta: {title: '数据采集', classify: 'collect', icon: 'collect', affix: true, level: 2, role: 2, isShow: 1},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/collect/index'),
                name: 'collectIndex',
                meta: {title: '数据采集', classify: 'collect', icon: 'collect', affix: true, level: 2, role: 2, isShow: 1}
            },
        ])
    },
    {
        path: '/activity',
        component: platform,
        redirect: '/activity/index',
        name: "activity",
        meta: {title: '活动配置', classify: 'activity', icon: 'activity', affix: true, level: 2, role: 2, isShow: 1},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/activity/index'),
                name: 'activityIndex',
                meta: {title: '活动配置', classify: 'activity', icon: 'activity', affix: true, level: 2, role: 2, isShow: 1}
            },
        ])
    },
    {
        path: '/dbBlast',
        component: platform,
        redirect: '/dbBlast/index',
        name: "dbBlast",
        meta: {title: '数据库比对', classify: 'dbBlast', icon: 'dbBlast', affix: true, level: 2, role: 2, isShow: 1},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/dbBlast/index'),
                name: 'dbBlastIndex',
                meta: {title: '数据库比对', classify: 'dbBlast', icon: 'dbBlast', affix: true, level: 2, role: 2, isShow: 1}
            },
        ])
    },
    {
        path: '/runLog',
        component: empty,
        redirect: '/runLog/index',
        name: "runLog",
        meta: {title: '运行日志', classify: 'runLog', icon: 'runLog', affix: true, level: 2, role: 2, isShow: 1},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/runLog/index'),
                name: 'runLogIndex',
                meta: {title: '运行日志', classify: 'runLog', icon: 'runLog', affix: true, level: 2, role: 2, isShow: 1}
            },
        ])
    },
])

const constantRoutes = [
    ...openRoutes,
    ...toolsRoutes1,
    ...articleRoutes,
    ...goodsRoutes,
    ...orderRoutes,
    ...teamRoutes,
    ...distributorRoutes,
    ...financialRoutes,
    ...toolsRoutes,
    ...providerRoutes,
    ...systemRoutes
];

const router = createRouter({
    history: createWebHashHistory(),
    routes: constantRoutes
});

//TODO: 先实现下一级的过滤，
function filterByKey(arrayObj) {
    let index = arrayObj.findIndex((item) => {
        return item["meta"]["isShow"] === 0
    })
    if (index > -1) {
        arrayObj.splice(index, 1);
    }
    return arrayObj
}

export {
    toolsRoutes1,
    articleRoutes,
    goodsRoutes,
    teamRoutes,
    distributorRoutes,
    orderRoutes,
    financialRoutes,
    toolsRoutes,
    providerRoutes,
    systemRoutes,
    constantRoutes,
    router
}