/**
 * Created by 胡子哥 on 2021/09/12.
 */
import {$http} from '../common/axios.js';
import {_CONFIG} from '../config/config.js';

class ServicesGlobal {
    constructor() {
        this.path = {
            api: _CONFIG.apiPath + "/boss",
            www: _CONFIG.apiPath + "/www",
            apiMg: _CONFIG.api.apiThirdPath + "/mg",
            apiPublic: _CONFIG.apiPath + "/www/public",
        }
    }

    postParams(obj) {
        let params = new URLSearchParams();
        for (let key in obj) {
            params.append(key, obj[key]);
        }
        return params;
    }

    //获取验证码
    getImgCode(data) {
        return $http.get(`${this.path.apiPublic}/imgCaptcha`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }

    upFile(data = {}) {
        let params = this.postParams(data);
        return $http.post(`${this.path.api}/articleClass/upFile`, params)
            .then(function (response) {
                return response.data;
            });
    }

    getQrCode(url) {
        return $http.get(url)
            .then(function (response) {
                return response.data;
            });
    }

    getTableFields(data = {}) {
        return $http.get(`${this.path.api}/tools/getTableFields`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }

    // router /boss/team/exportExcel
    exportExcel(routerName, data) {
        let exportMap = {
            "goods_order": "/order/exportExcel",
            "goods_orderPo_pay": "/orderPoPay/exportExcel",
            "team": "/team/exportExcel",
            "invoice_order": "/invoice/exportExcel",
            "goods_details_list": "/financial/exportExcelDetailsList",
            "general_ledger_in": "/financial/exportExcelGeneral_ledger_in",
            "general_ledger_out": "/financial/exportExcelGeneral_ledger_out",
            "withdrawal": "/withdrawal/exportExcel",
            "goods_order_po_refund": "/orderPoRefund/exportExcel",
            "return_products_user":"/returnProductsUser/exportExcel",
            "recharge_order":"/rechargeOrder/exportExcel",
            "goods_package_pay":"/bagPay/exportExcel",
            "user_extract_record_3":"/bagBean/exportExcel",
            "user_extract_record_1":"/bagExtract/exportExcel",
            "user_extract_record_2":"/bagWithdrawal/exportExcel",
            "recharge_card_pay":"/cardPay/exportExcel",
            "user":"/user/exportExcel",
            "user_recharge_rmb":"/rechargeRmb/exportExcel",
            "recharge_card":"/rechargeCard/exportExcel"

        }
        let router = exportMap[routerName]
        return $http.get(`${this.path.api}${router}`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }

    comparePassword(data = {}) {
        let params = this.postParams(data);
        return $http.post(`${this.path.api}/boss/comparePassword`, params)
            .then(function (response) {
                return response.data;
            });
    }

    setComparePassword(data = {}) {
        let params = this.postParams(data);
        return $http.post(`${this.path.api}/boss/setComparePassword`, params)
            .then(function (response) {
                return response.data;
            });
    }
}

export {
    ServicesGlobal
};