<template>
  <router-view/>
</template>

<script>
// import axios from 'axios';

export default {
  name: 'App',
  components: {},
  data() {
    return {
      breadCrumbs: [],
    }
  },
  created() {
    /*localStorage.setItem('userInfo','')
    if(!localStorage.getItem('TOKEN')||!localStorage.getItem('userInfo')){
      localStorage.clear()
      this.$router.push('login');
    }*/
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.if-browser {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.8);
}
</style>
