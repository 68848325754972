let _OPTIONS = {
    "teamType": [
        {"label": "个人", "value": 1, "checked": 0, "disabled": true},
        {"label": "企业", "value": 2, "checked": 0, "disabled": false},
        {"label": "政务", "value": 3, "checked": 0, "disabled": false},
        {"label": "学校", "value": 4, "checked": 0, "disabled": false}
    ],
    "adminRole": [
        {"label": "系统", "value": 1, "checked": 0, "disabled": true},
        {"label": "团队负责人", "value": 2, "checked": 0, "disabled": false},
        {"label": "普通管理员", "value": 10, "checked": 0, "disabled": false}
    ],
    "adminLevel": [
        {"label": "ROOT", "value": 1, "checked": 0, "disabled": true},
        {"label": "系统管理员", "value": 2, "checked": 0, "disabled": true},
        {"label": "超级管理员", "value": 3, "checked": 0, "disabled": false},
        {"label": "高级管理员", "value": 4, "checked": 0, "disabled": false},
        {"label": "普通管理员", "value": 5, "checked": 1, "disabled": false}
    ],
    "userRole": [
        {"label": "员工", "value": 1, "checked": 0, "disabled": true},
        {"label": "商务会员", "value": 2, "checked": 0, "disabled": false},
        {"label": "非员工批量导入人员", "value": 11, "checked": 0, "disabled": false},
        {"label": "其他类型", "value": 20, "checked": 0, "disabled": false}
    ],
    "userLevel": [
        {"label": "A级", "value": 1, "checked": 0, "disabled": false},
        {"label": "B级", "value": 2, "checked": 0, "disabled": false},
        {"label": "C级", "value": 3, "checked": 0, "disabled": false},
        {"label": "D级", "value": 4, "checked": 0, "disabled": false},
        {"label": "E级", "value": 5, "checked": 0, "disabled": false},
        {"label": "F级", "value": 6, "checked": 0, "disabled": false},
        {"label": "G级", "value": 7, "checked": 0, "disabled": false},
        {"label": "H级", "value": 8, "checked": 0, "disabled": false},
        {"label": "I级", "value": 9, "checked": 0, "disabled": false},
        {"label": "J级", "value": 10, "checked": 0, "disabled": false}
    ],
    "articleAttr": [
        {"label": "普通", "value": 1, "checked": 1},
        {"label": "推荐", "value": 2, "checked": 0},
        {"label": "置顶", "value": 3, "checked": 0},
        {"label": "头条", "value": 4, "checked": 0}
    ],
    "status": [
        {"label": "正常", "value": 1, "checked": 1},
        {"label": "回收", "value": 2, "checked": 0}
    ],
    "systemMessageRole": [
        {"label": "企业员工", "value": 1, "checked": 0},
        {"label": "商务会员", "value": 2, "checked": 0},
        {"label": "其他", "value": 3, "checked": 0},
        {"label": "不限用户", "value": 10, "checked": 1},
    ],
    "if": [
        {"label": "是", "value": 1, "checked": 1},
        {"label": "否", "value": 2, "checked": 0}
    ],
    "statusUser": [
        {"label": "正常", "value": 1, "checked": 1},
        {"label": "禁用", "value": 2, "checked": 0}
    ],
    "statusGoods": [
        {"label": "正常售卖", "value": 1, "checked": 1},
        {"label": "商品下线", "value": 2, "checked": 0}
    ],
    "statusPackage": [
        {"label": "待激活", "value": 1, "checked": 1},
        {"label": "已激活", "value": 2, "checked": 0},
        {"label": "已停止", "value": 3, "checked": 0}
    ],
    "payStatus": [
        {"label": "已支付", "value": 1, "checked": 0},
        {"label": "待支付", "value": 2, "checked": 0},
        {"label": "支付失败", "value": 3, "checked": 0},
        {"label": "待确认", "value": 4, "checked": 0},
        {"label": "已取消", "value": 5, "checked": 0}
        /*
        {"label": "已完成", "value": 6, "checked": 0},*/
    ],
    "shippingStatus": [
        {"label": "未发货", "value": 0, "checked": 0},
        {"label": "部分发货", "value": 1, "checked": 0},
        {"label": "已发货", "value": 2, "checked": 0},
        {"label": "已签收", "value": 3, "checked": 0}
    ],
    "activatedStatus": [
        {"label": "已激活", "value": 1, "checked": 1},
        {"label": "未激活", "value": 2, "checked": 0}
    ],
    "issuedStatus": [
        {"label": "是", "value": 1, "checked": 1},
        {"label": "否", "value": 2, "checked": 0}
    ],
    "cardStatus": [
        {"label": "正常", "value": 1, "checked": 1},
        {"label": "待充值", "value": 2, "checked": 0},
        {"label": "支付中", "value": 3, "checked": 0},
        {"label": "已禁用", "value": 4, "checked": 0}
    ],
    "payment": [
        {"label": "线下对公账号", "value": 1, "checked": 0}
        /*{"label": "微信", "value": 2, "checked": 0},
        {"label": "支付宝", "value": 3, "checked": 0},*/
    ],
    "withdrawalModel": [
        {"label": "支付宝", "value": 1, "checked": 0},
        {"label": "微信", "value": 2, "checked": 0},
        {"label": "银行卡", "value": 3, "checked": 0}
    ],
    "payStatusMG": [
        {"label": "未支付", "value": 0, "checked": 0},
        {"label": "已支付", "value": 2, "checked": 0}
    ],
    "orderStatusMG": [
        {"label": "待付款", "value": 0, "checked": 0},
        {"label": "待发货", "value": 1, "checked": 0},
        {"label": "已发货", "value": 3, "checked": 0},
        {"label": "已取消", "value": 5, "checked": 0},
        {"label": "已完成", "value": 11, "checked": 0}
    ],
    "shippingStatusMG": [
        {"label": "未发货", "value": 0, "checked": 0},
        {"label": "部分发货", "value": 1, "checked": 0},
        {"label": "已发货", "value": 2, "checked": 0},
        {"label": "已签收", "value": 3, "checked": 0}
    ],
    "orderStatusProductBLH": [
        {"label": "已取消", "value": 0, "checked": 0},
        {"label": "待确认", "value": 1, "checked": 0},
        {"label": "待处理", "value": 3, "checked": 0},
        {"label": "待出库", "value": 4, "checked": 0},
        {"label": "部分出库", "value": 5, "checked": 0},
        {"label": "出库完成", "value": 8, "checked": 0}
    ],
    "orderStatusInfoProductBLH": [
        {"label": "待出库", "value": 1, "checked": 0},
        {"label": "已出库", "value": 2, "checked": 0},
        {"label": "已取消", "value": 3, "checked": 0}
    ],
    "orderStatusBLH": [
        {"label": "已取消", "value": 0, "checked": 0},
        {"label": "待确认", "value": 1, "checked": 0},
        {"label": "待处理", "value": 3, "checked": 0},
        {"label": "待出库", "value": 4, "checked": 0},
        {"label": "部分出库", "value": 5, "checked": 0},
        {"label": "出库完成", "value": 8, "checked": 0}
    ],
    "expressStatusBLH": [
        {"label": "揽件", "value": 0, "checked": 0},
        {"label": "在途", "value": 1, "checked": 0},
        {"label": "派件", "value": 2, "checked": 0},
        {"label": "已签收", "value": 3, "checked": 0},
        {"label": "派送失败", "value": 4, "checked": 0},
        {"label": "疑难件", "value": 5, "checked": 0},
        {"label": "退件", "value": 6, "checked": 0}
    ],
    "orderType": [
        //{"label": "公司采购", "value": 1, "checked": 0},
        {"label": "员工订单", "value": 1, "checked": 0},
        {"label": "个人订单", "value": 20, "checked": 0},
        {"label": "商务订单", "value": 2, "checked": 0},
    ],
    "orderTypeGroup": [
        {"label": "全部订单", "value": 0, "checked": 0},
        {"label": "公司采购", "value": 1, "checked": 0},
        {"label": "员工订单", "value": 2, "checked": 0},
        {"label": "商务订单", "value": 3, "checked": 0}
    ],
    "auditStatus": [
        {"label": "通过", "value": 1, "checked": 0},
        {"label": "审核中", "value": 2, "checked": 0},
        {"label": "不通过", "value": 3, "checked": 0}
    ],
    "categoryType": [
        {"label": "频道", "value": 1, "checked": 0},
        {"label": "单页", "value": 2, "checked": 0},
        {"label": "列表", "value": 3, "checked": 0},
        {"label": "外链", "value": 4, "checked": 0}
    ],
    "goodsType": [
        {"label": "zy-自营实物", "value": 5, "checked": 1},
        {"label": "blh-自营实物", "value": 1, "checked": 0},
        {"label": "blh-电子卡券", "value": 2, "checked": 0},
        {"label": "blh-直充话费", "value": 3, "checked": 0},
        {"label": "blh-京东电商", "value": 4, "checked": 0}
    ],
    "taskType": [
        {"label": "互助类", "value": 1, "checked": 0},
        {"label": "悬赏类", "value": 2, "checked": 0}
    ],
    "navType": [
        {"label": "站内链接", "value": 1, "checked": 1},
        {"label": "站外链接", "value": 2, "checked": 0}
    ],
    "sex": [
        {"label": "男", "value": 1, "checked": 0},
        {"label": "女", "value": 2, "checked": 0}
    ],
    "noticeType": [
        {"label": "系统消息", "value": 1, "checked": 0},
        {"label": "通知公告", "value": 2, "checked": 1}
    ],
    "byPrice": [
        {"label": "价格从低到高", "value": "price_0", "checked": 0},
        {"label": "价格从高到低", "value": "price_1", "checked": 0}
    ],
    "bySales": [
        {"label": "销量从低到高", "value": "sales_0", "checked": 0},
        {"label": "销量从高到低", "value": "sales_1", "checked": 0}
    ],
    "providerType": [
        {"label": "平台自采", "value": "1", "checked": 0, "disabled": false},
        {"label": "线下供应商", "value": "2", "checked": 0, "disabled": false},
        {"label": "线上供应链", "value": "3", "checked": 0, "disabled": false}
    ],
    "goodsChannel": [
        {"label": "zy-自营实物", "value": 8, "checked": 0},
        {"label": "blh-自营渠道", "value": 1, "checked": 0},
        {"label": "blh-卡券渠道", "value": 2, "checked": 0},
        {"label": "blh-直充渠道", "value": 3, "checked": 0},
        {"label": "blh-京东电商渠道", "value": 4, "checked": 0},
        {"label": "blh-天猫电商渠道", "value": 5, "checked": 0},
        {"label": "blh-至甑云彩渠道", "value": 6, "checked": 0},
        {"label": "blh-小米渠道", "value": 7, "checked": 0}
    ],
    "orderModel": [
        {"label": "退钱", "value": 0, "checked": 0},
        {"label": "实物发货", "value": 1, "checked": 0},
        {"label": "卖了换钱", "value": 2, "checked": 0},
        {"label": "分享", "value": 3, "checked": 0}
    ],
    "orderTypeMap": [
        {"label": "个人充值", "value": "GRCZ", "checked": 0},
        {"label": "个人充值", "value": "AR", "checked": 0},
        {"label": "企业采购", "value": "PAY", "checked": 0},
        {"label": "企业采购", "value": "PO", "checked": 0},
        {"label": "礼包", "value": "BP", "checked": 0},
        {"label": "充值卡", "value": "C", "checked": 0},
        {"label": "个人换钱", "value": "W", "checked": 0},
        {"label": "分享出去的换钱", "value": "EW", "checked": 0},
        {"label": "自选采购", "value": "G", "checked": 0}
    ],
    "scmMap":[
        {
            "label": "百礼汇",
            "value": 2,
            "checked": 0
        },
        {
            "label": "睿桃自营",
            "value": 3,
            "checked": 0
        }
    ],
    "orderGoodsStatus":[
        {"label": "待出库", "value": 1, "checked": 0},
        {"label": "已出库", "value": 2, "checked": 0},
        {"label": "已取消", "value": 3, "checked": 0},
    ],
    "msgType":[
        {"label": "平台消息", "value": 1, "checked": 0},
        {"label": "团队消息", "value": 2, "checked": 0},
        {"label": "个人消息", "value": 3, "checked": 0},
    ],
    "mch": [
        {"label": "微信", "value": 1, "checked": 0},
        {"label": "支付宝", "value": 2, "checked": 0}
    ],
    "bagType": [
        { "label": "无", "value": 0, "checked": 0 },
        { "label": "收货", "value": 1, "checked": 0 },
        { "label": "换钱", "value": 2, "checked": 0 },
        { "label": "换豆", "value": 3, "checked": 0 },
        { "label": "收货/换钱/换豆", "value": 4, "checked": 0 }
    ]
};

export {
    _OPTIONS
}