import {$http} from '../common/axios.js';
import {ServicesGlobal} from './services.js';

class FinancialServices extends ServicesGlobal {
    constructor(props) {
        super(props);
    }

    showGeneralLedgerIn(data = {}) {
        return $http.get(`${this.path.api}/financial/showGeneralLedgerIn`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }

    showGeneralLedgerOut(id,data = {}) {
        return $http.get(`${this.path.api}/financial/showGeneralLedgerOut`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }

    showUserIncome(uid,data = {}) {
        return $http.get(`${this.path.api}/financial/showUserIncome?uid=${uid}`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }

    showUserExpense(uid,data = {}) {
        return $http.get(`${this.path.api}/financial/showUserExpense?uid=${uid}`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }

    showTeamIncome(teamId,data = {}) {
        return $http.get(`${this.path.api}/financial/showTeamIncome?team_id=${teamId}`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }

    showTeamExpense(teamId,data = {}) {
        return $http.get(`${this.path.api}/financial/showTeamExpense?team_id=${teamId}`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }

    makeGeneralLedgerIn(data = {}) {
        return $http.get(`${this.path.api}/financial/makeGeneralLedgerIn`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }

    makeGeneralLedgerOut(data = {}) {
        return $http.get(`${this.path.api}/financial/makeGeneralLedgerOut`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }

    makeGoodsDetailsList(data = {}) {
        return $http.get(`${this.path.api}/financial/makeGoodsDetailsList`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }

    goodsDetailsList(data = {}) {
        return $http.get(`${this.path.api}/financial/goodsDetailsList`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }

    getOrderInfoSome(order_id){
        console.log("",order_id)
        let order_id_array = order_id.split("-")
        let map = {
            GRCZ:{"label": "个人充值", "value": "GRCZ","url":`/rechargeRmb/detailsByOrderId/${order_id}`},
            AR:{"label": "个人充值", "value": "AR", "url": `/rechargeRmb/detailsByOrderId/${order_id}`},
            PAY:{"label": "企业采购", "value": "PAY", "url":""},
            PO:{"label": "企业采购", "value": "PO", "url": ""},
            BP:{"label": "礼包", "value": "BP", "url": ""},
            C:{"label": "充值卡", "value": "C", "url": ""},
            W:{"label": "个人换钱", "value": "W", "url": ""},
            EW:{"label": "分享出去的换钱", "value": "EW", "url": ""},
            G:{"label": "自选采购", "value": "G", "url": ""}
        }
        let tag = map[order_id_array[0]] || ""
        if(!tag){
            return
        }

        let url = map[order_id_array[0]].url

        let data = {}
        return $http.get(`{boss}${url}`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }
}


export default (new FinancialServices())