<template>
  <PageSidebar></PageSidebar>
  <div class="main">
    <PageHeader></PageHeader>
    <router-view></router-view>
  </div>

  <ifBrowsers></ifBrowsers>
</template>

<script>
import PageHeader from "./components/PageHeader";
import PageSidebar from "./components/PageSidebar";
import ifBrowsers from '../components/ifBrowsers/index.vue'

export default {
  name: "platform",
  components: {
    PageHeader,
    PageSidebar,
    ifBrowsers
  },
  data() {
    return {
      active: 0,
    };
  },
  mounted() {
    document.getElementById("body").className = "body-height-100"
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
}

.data-view {
  flex: 1;
  box-sizing: border-box;
  background: #fff;
  padding: 20px 30px;
  margin-left: 16px;
  margin-bottom: 16px;
  margin-right: 16px;
}
</style>