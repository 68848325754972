<template>
    <div>
        <router-view/>
    </div>
</template>

<script>
export default {
  mounted() {
    document.getElementById("body").className = "body-empty"
  },
}
</script>

<style lang="scss" scoped>
.print-body{
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>